@import './vars';

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 34px;
  line-height: 20px;
  font-size: 16px;
  font-weight: $weight-500;
  font-family: $Mulish;
  border-radius: $border-radius-12;
  cursor: pointer;
  transition: $transition-default;

  .icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
  }

  &_primary {
    color: $color-white;
    background-color: $color-accent-orange-primary;

    &:hover {
      background-color: $color-accent-hover-state;
    }

    &:active {
      background-color: $color-accent-click-state;
    }

    &:disabled {
      background-color: $color-text-disabled-bg;
      color: $color-text-disabled;
    }
  }

  &_secondary {
    color: $color-accent-orange-primary;
    border: 1px solid $color-accent-orange-primary;

    &:hover {
      color: $color-accent-hover-state;
      border-color: $color-accent-hover-state;
    }

    &:active {
      color: $color-accent-click-state;
      border-color: $color-accent-click-state;
    }

    &:disabled {
      background-color: $color-text-disabled-bg;
      color: $color-text-disabled;
    }
  }

  &_back {
    padding: 0;
    width: auto;
    line-height: 24px;
    font-size: 17px;
    color: $color-black;
    background-color: $color-white;

    &:hover {
      color: $color-black;
    }

    .icon {
      margin-right: 14px;
      margin-left: 6px;
      width: 11px;
      color: $color-text-tertiary;
    }

    &:disabled {
      background-color: $color-text-disabled-bg;
      color: $color-text-disabled;
    }
  }
}

// TODO: add sizes (paddings)
