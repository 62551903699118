// grid
$container-xl: 1440px;
$container: 1160px;
$container-form: 1057px;
$container-md: 960px;

// font
$Mulish: Mulish,
sans-serif;
$Roboto: Roboto,
sans-serif;

// color figma
$color-character-title: #000000D9;
$color-character-disabled-placeholder: #00000040;
$color-sidebar-bg: #282828;

// COLORS
// text colors
$color-text-primary: #392927;
$color-text-placeholder: #b3b3b3;
$color-text-secondary: #5C5C5C;
$color-text-tertiary: #747474;
$color-text-icons: #7C7B7B;
$color-white: #FFFFFF;
$color-black: #000000;
$color-stroke: #D7D7D7;
$color-dividers: #EDEDED;
$color-text-disabled: #8A8A8A;
$color-text-disabled-bg: #EFEFEF;

// accent orange colors
$color-accent-orange-primary: #FF6C2D;
$color-accent-orange-secondary: #FD9062;
$color-accent-orange-tertiary: #FFB596;
$color-accent-orange-quaternary: #FFF2EC;
$color-accent-hover-state: #F05B1B;
$color-accent-click-state: #D03E00;

// system colors
$color-successful: #3AC922;
$color-successful-hover: #28B411;
$color-successful-secondary: #B2FFA6;
$color-successful-tertiary: #E6FFE2;

$color-attention: #F6D523;
$color-attention-hover: #F6D523;
$color-attention-secondary: #FFEF98;
$color-attention-tertiary: #FFFAE1;

$color-error: #FF3030;
$color-error-hover: #ED2121;
$color-error-secondary: #FFC0C0;
$color-error-tertiary: #FFF2F2;

// background colors
$color-bg-light: #FFFFFF;
$color-bg-aqua: #EBF4F4;
$color-bg-aqua-secondary: #F7FAFA;
$color-bg-modal: #35394026;
$blur-bg-modal: 4px;

// gradient colors
$color-first: #FF6C2D;
$color-last: #FFB596;

// border colors
$color-dark-grayscale-dividers: #323232;

// additional
$color-register-password: #6B7594;

$color-purple: #112FB4;
$color-purple-secondary: #A7A6FF;
$color-purple-tertiary: #E6E6FF;

$color-blue: #1178B4;
$color-blue-secondary: #A6DEFF;

// * old start *
// color
$color-light: #FFFFFF;
$color-light-100: #F7F9FA;
$color-light-200: #F2F2F2;
$color-light-300: #D9D9D9;

$color-dark: #000000;
$color-dark-200: #1A1E26;
$color-dark-300: #373737;
$color-dark-400: #878787;

$color-gray-30: #6F7D87;

$color-control: #F0F3F5;
$color-control-border: #C7CAD6;
$color-avatar-bg: #00000033;
$color-avatar-confirm: #878FA8;
$color-sidebar-text: #8B8B8B;
$color-sidebar-img: #4A4A4A;
$color-stage-border: #F0F0F0;
$color-stage-text: #00000073;
$color-general-title: #CFD5DA;
$color-stepper-rode-line: #EDEDED;
$color-button-exit: #A9B4BC;
$color-signature-bg: #F5F5F5;
// * old end *

// box-shadow
$box-shadow-avatar-1: 0px 10px 40px 0px #0000000A;
$box-shadow-avatar-2: 0px 4px 8px 0px #00000008;
$box-shadow-menu-1: 0px 4px 8px 0px #0000000F;
$box-shadow-menu-2: 0px 10px 40px 0px #0000000A;
$box-shadow-welcome: 0px 4px 20px 0px #84848433;

// BORDERS
// border-radius
$border-radius-4: 4px;
$border-radius-5: 5px;
$border-radius-8: 8px;
$border-radius-10: 10px;
$border-radius-12: 12px;
$border-radius-16: 16px;
$border-radius-20: 20px;

// * old start *
$border-radius: 10px;
$border-radius-control: 8px;
$border-radius-btn: 12px;
$border-radius-intro: 16px;
$border-radius-step-stage: 32px;
$border-radius-scan-img: 34.4px;
$border-radius-50: 50%;
$border-radius-child-passport: 4px;
// * old end *

// weight
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;

// font
$font-size: 14px;
$line-height: 20px;

// letter spacing
$spacing-33: 0.33px;
$spacing-1: 0.01em;
$spacing--001: -0.01em;
$letter-spacing--025: -0.25px;

// transition
$transition-default: all .3s ease;

// z-index
$z-index-80: 80;
$z-index-90: 90;
$z-index-100: 100;
$z-index--1: -1;
