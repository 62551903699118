@import '../vars';

.mat-tab-group {
  font-family: $Mulish;
}

.mat-tab-header {
  border-bottom: none;
}

.mat-tab-body {
  height: 100%;
}

.mat-ink-bar {
  display: none;
}

.mat-tab-labels {
  gap: 16px;
  margin-bottom: 40px;

  .mat-ripple {
    display: flex !important;
  }

  .mat-tab-label {
    padding: 8px 16px;
    opacity: 1;
    min-width: auto;
    height: auto;
    border-radius: $border-radius-12;
    background-color: $color-accent-orange-quaternary;
    transition: $transition-default;

    &:hover {
      background-color: $color-accent-hover-state;

      .mat-tab-label-content {
        color: $color-white;
      }
    }

    &:active {
      background-color: $color-accent-click-state;
    }

    &-active {
      background-color: $color-accent-orange-primary;

      .mat-tab-label-content {
        color: $color-white;
      }
    }
  }

  .mat-tab-label-content {
    letter-spacing: $spacing-1;
    font-size: 15px;
    color: $color-text-primary;
    font-family: $Mulish;
  }
}

// profile-view
.mat-tab-group {
  &.profile-view {
    .mat-tab-labels {
      flex-wrap: wrap;
    }
  }
}
