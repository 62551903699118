@import '../vars';

.cdk-overlay-container {
  .cdk-overlay-dark-backdrop {
    background-color: $color-bg-modal;
    backdrop-filter: blur($blur-bg-modal);
  }
  .mat-dialog-container {
    position: relative;
    padding: 0;
    max-width: 606px;
    border-radius: $border-radius-12;
    box-shadow: $box-shadow-menu-1, $box-shadow-menu-2;
  }
}

// employee-details modal
.cdk-overlay-container {
  .cdk-overlay-pane {
    &.employee-details {
      .mat-dialog-container {
        max-width: 1034px;
        max-height: 764px;
      }
    }
  }
}
