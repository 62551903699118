@import '../vars';

.mat-radio-group {
  display: flex;
  gap: 16px 24px;

  &.passport {
    flex-direction: column;
  }
}
.mat-radio-button {
  font-family: $Mulish;

  .mat-radio-label {
    .mat-radio-container {
      width: 24px;
      height: 24px;

      .mat-radio-outer-circle {
        width: 24px;
        height: 24px;
        border: 2px solid $color-stroke;
        border-radius: $border-radius-50;
        transition: $transition-default;
      }

      .mat-radio-inner-circle {
        width: 12px;
        height: 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.001);
        background-color: $color-bg-light;
        transition: $transition-default;
      }
    }
    .mat-radio-label-content {
      padding-left: 16px;
      letter-spacing: $spacing-1;
      line-height: 20px;
      font-size: 15px;
      font-weight: $weight-500;
      color: $color-text-primary;
    }
  }

  &:hover {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: $color-accent-hover-state;
        }
      }
    }
  }

  &:active {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: $color-accent-click-state;
        }
      }
    }
  }
}

// checked radio
.mat-radio-checked {
  .mat-radio-label {
    .mat-radio-container {
      .mat-radio-outer-circle {
        border: 7px solid $color-accent-orange-primary;
      }

      .mat-radio-inner-circle {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &:hover {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border: 7px solid $color-accent-hover-state;
        }

        .mat-radio-inner-circle {
          background-color: $color-bg-light;
        }
      }
    }
  }

  &:active {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: $color-accent-click-state;
        }

        .mat-radio-inner-circle {
          background-color: $color-bg-light;
        }
      }
    }
  }
}
