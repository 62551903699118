@import '../vars';

// employer register textarea
.mat-form-field {
  &.textarea_register {
    width: 100%;

    textarea.mat-input-element {
      padding: 0;
      line-height: 24px;
      font-size: 17px;
      color: $color-text-primary;

      &::placeholder {
        line-height: 24px;
        font-size: 17px;
        color: $color-text-primary;
      }
    }

    .mat-form-field-flex {
      padding: 28px 16px 8px;
      border-radius: $border-radius-10;
      border: 1px solid $color-stroke;
      cursor: text;
    }
    .mat-form-field-infix {
      .mat-form-field-label-wrapper {
        display: block;
        line-height: 16px;
        top: -22px;
        padding-top: 16px;
      }
    }
    .mat-form-field-label {
      letter-spacing: $spacing--001;
      line-height: 16px;
      font-size: 13px;
      font-weight: $weight-500;
      color: $color-text-tertiary;
      transform: translateY(-1.34375em) scale(1);
    }
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 0;
    top: calc(100% - -2px);
  }

  .mat-form-field-hint-spacer {
    display: none;
  }

  .mat-form-field-hint-end {
    letter-spacing: $spacing--001;
    font-size: 13px;
    line-height: 16px;
    font-weight: $weight-500;
    color: $color-text-primary;
    word-spacing: -3px;
  }

  &.mat-focused {
    textarea.mat-input-element::placeholder {
      opacity: 0;
    }
  }
}

// textarea stepper
.mat-form-field {
  &.textarea_company,
  &.textarea_stepper {
    width: 100%;

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding: 28px 16px 8px;
        border-radius: $border-radius-10;
        border: 1px solid $color-bg-aqua;
        background-color: $color-bg-aqua;
        cursor: text;
        transition: $transition-default;

        .mat-form-field-infix {
          textarea.mat-input-element {
            padding: 0;
            line-height: 24px;
            font-size: 17px;
            color: $color-text-primary;

            &::placeholder {
              font-weight: $weight-500;
              font-family: $Mulish;
              color: $color-text-placeholder;
            }
          }
          .mat-form-field-label-wrapper {
            display: block;
            top: -20px;
            padding-top: 16px;
            line-height: 16px;
            //opacity: 0;

            .mat-form-field-label {
              width: 100%;
              letter-spacing: $spacing--001;
              line-height: 16px;
              font-size: 13px;
              font-weight: $weight-500;
              color: $color-accent-orange-primary;
              transform: translateY(-9px) scale(1);
              transition: $transition-default;
            }
          }
        }
      }
    }
    .mat-hint {
      letter-spacing: $spacing--001;
      font-size: 13px;
      line-height: 16px;
      font-weight: $weight-500;
      color: $color-text-primary;
    }

    &.mat-focused {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          border-color: $color-accent-orange-primary;

          .mat-form-field-infix {
            .mat-form-field-label-wrapper {
              //opacity: 1;
              //top: -9px;
            }
            textarea.mat-input-element {
              //transform: translateY(9px);
            }
          }
        }
      }
    }

    &:hover {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            textarea.mat-input-element {
              //color: $color-text-secondary;

              &::placeholder {
                //color: $color-text-secondary;
              }
            }
          }
        }
      }
    }

    &.ng-touched.ng-valid:not(:empty):not(.mat-focused) {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            .mat-form-field-label-wrapper {
              //opacity: 1;
              //top: -9px;
            }

            textarea.mat-input-element {
              //transform: translateY(9px);
            }

            .mat-form-field-label {
              color: $color-text-tertiary;
            }
          }

          &:hover {
            .mat-form-field-infix {
              .mat-form-field-label-wrapper {
                //opacity: 0;
              }

              textarea.mat-input-element {
                //transform: translateY(0);
              }
            }
          }
        }
      }
    }
  }
}
