@import '../vars';

.cdk-overlay-pane {
  .mat-menu-panel {
    min-height: auto;
    border-radius: $border-radius-8;
    box-shadow: $box-shadow-menu-1, $box-shadow-menu-2;

    .mat-menu-content:not(:empty) {
      padding-top: 0;
      padding-bottom: 0;
    }
    .mat-menu-content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .mat-menu-item {
        padding: 0;
        height: auto;
        letter-spacing: $spacing--001;
        line-height: 16px;
        font-size: 13px;
        font-weight: $weight-500;
        color: $color-text-primary;
        font-family: $Mulish;
        transition: $transition-default;

        &:hover:not([disabled]) {
          color: $color-accent-hover-state;
          background: transparent;
        }
      }
    }
  }

}
