@import "../vars";

// job-details
.mat-tab-body-wrapper {
  overflow: visible !important;
}

.mat-tab-body {
  overflow-x: visible;

  &.mat-tab-body-active {
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  .mat-tab-group {
    font-family: $Mulish;
  }

  .mat-tab-body-content {
    overflow: visible;
  }
}

table.mat-table {
  width: 100%;
  font-family: $Mulish;

  thead {
    width: 100%;
  }

  tr.mat-header-row {
    display: flex;
    gap: 20px;
    height: 39px;
    border-bottom: 1px solid $color-dividers;

    @media screen and (max-width: 590px) {
      gap: 5px;
    }
  }

  tr.mat-row {
    height: 88px;
    position: relative;
    display: flex;
    gap: 20px;
    border-bottom: 1px solid $color-dividers;
    cursor: pointer;
    transition: $transition-default;
    z-index: $z-index-90;

    @media screen and (max-width: 910px) {
      // height: 60px;
      gap: 5px;
    }

    @media screen and (max-width: 480px) {
      max-width: 96vw;
      gap: 5px;
    }

    &:hover {
      border-bottom-color: $color-bg-aqua;

      &::before {
        opacity: 1;
      }

      // worker tr menu
      .menu {
        opacity: 1;
      }
    }
  }

  th.mat-header-cell {
    letter-spacing: $spacing--001;
    line-height: 16px;
    font-size: 13px;
    font-weight: $weight-500;
    font-family: $Mulish;
    color: $color-text-tertiary;
    border-bottom: none;

    @media screen and (max-width: 650px) {
      font-size: 10px;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  td.mat-cell {
    display: flex;
    align-items: center;
    padding: 24px 0;
    height: auto;
    border-bottom: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .avatar {
      // margin-right: 12px;
      min-width: 40px;

      @media screen and (max-width: 640px) {
        min-width: 20px;
      }
    }
  }
}

// workers table
.table.mat-table {
  &.workers {
    // width header & cell
    th.mat-header-cell,
    td.mat-cell {
      &.name {
        // width: 252px;
        flex: 1 0.8 252px;
      }

      &.status {
        flex: 1 2 252px;
        min-width: 20px;

        @media screen and (max-width: 650px) {
          font-size: 10px;
        }
      }

      &.works,
      &.search {
        span {
          padding: 4px 8px;
          letter-spacing: $spacing--001;
          line-height: 16px;
          font-size: 11px;
          font-weight: $weight-500;
          border-radius: $border-radius-5;
          border: 1px solid transparent;
        }
      }

      &.search span {
        color: $color-attention-hover;
        background-color: $color-attention-tertiary;
        border-color: $color-attention-hover;
      }

      &.works span {
        color: $color-successful-hover;
        background-color: $color-successful-tertiary;
        border-color: $color-successful-hover;
      }

      &.phone {
        width: 247px;

        @media screen and (max-width: 650px) {
          font-size: 10px;
        }
      }

      &.total-hours {
        // width: 114px;
        flex: 1 1 114px;
      }
    }

    td.mat-cell {
      &.name {
        letter-spacing: $spacing-1;
        font-size: 15px;
        font-weight: $weight-500;
        color: $color-text-primary;

        @media screen and (max-width: 650px) {
          font-size: 10px;
        }
      }

      &.phone,
      &.total-hours {
        letter-spacing: $spacing--001;
        line-height: 16px;
        font-size: 13px;
        font-weight: $weight-500;
        color: $color-text-primary;

        @media screen and (max-width: 650px) {
          font-size: 10px;
        }
      }
    }
  }
}

// employers table
.table.mat-table {
  &.employers {
    th.mat-header-cell,
    td.mat-cell {
      &.organization-name {
        // width: 252px;
        flex: 1 1 252px;
      }

      &.contact-name {
        flex: 1 1 200px;
        // width: 200px;
      }

      &.contacts {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex: 1 1 200px;
        // width: 200px;
      }

      &.number {
        flex: 1 1 210px;

        @media screen and (max-width: 510px) {
          flex: 1 1 242px;
        }
        // width: 210px;
      }
    }

    td.mat-cell {
      &.contacts {
        justify-content: center;
      }

      &.organization-name,
      &.contact-name,
      &.contacts .contact-phone,
      &.number {
        letter-spacing: $spacing-1;
        font-size: 15px;
        font-weight: $weight-500;
        color: $color-text-primary;

        @media screen and (max-width: 650px) {
          font-size: 10px;
        }
      }

      &.contacts .contact-email {
        letter-spacing: $spacing--001;
        line-height: 16px;
        font-size: 13px;
        font-weight: $weight-500;
        color: $color-text-tertiary;

        @media screen and (max-width: 650px) {
          font-size: 10px;
        }
      }

      &.organization-name,
      &.contact-name,
      &.contacts .contact-phone,
      &.contacts .contact-email,
      &.number,
      &.contacts {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }
}

td.mat-cell {
  &.status {
    &.active,
    &.open,
    &.completed,
    &.pending {
      span {
        padding: 4px 8px;
        letter-spacing: $spacing--001;
        line-height: 16px;
        text-transform: capitalize;
        font-size: 13px;
        font-weight: $weight-500;
        border-radius: $border-radius-5;
        border: 1px solid transparent;

        @media screen and (max-width: 550px) {
          padding: 2px 4px;
          font-size: 10px;
        }
      }
    }

    &.active span {
      color: $color-successful-hover;
      background-color: $color-successful-tertiary;
      border-color: $color-successful-hover;
    }

    &.open span {
      color: $color-blue;
      background-color: $color-blue-secondary;
      border-color: $color-blue;
    }

    &.completed span {
      color: $color-purple;
      background-color: $color-purple-tertiary;
      border-color: $color-purple;
    }

    &.pending span {
      color: $color-attention-hover;
      background-color: $color-attention-tertiary;
      border-color: $color-attention-hover;
    }

    &.waiting,
    &.started,
    &.working,
    &.not-started,
    &.completed,
    &.expectation {
      span {
        padding: 4px 8px;
        letter-spacing: $spacing--001;
        line-height: 16px;
        font-size: 13px;
        font-weight: $weight-500;
        border-radius: $border-radius-5;
        border: 1px solid transparent;

        @media screen and (max-width: 550px) {
          padding: 2px 4px;
          font-size: 10px;
        }
      }
    }

    &.waiting span {
      color: #fad201;
      background-color: $color-attention-tertiary;
      border-color: $color-attention-hover;
    }

    &.started span,
    &.working span {
      color: $color-successful;
      background-color: $color-successful-tertiary;
      border-color: $color-successful;
    }

    &.not-started span {
      color: $color-error;
      background-color: $color-error-tertiary;
      border-color: $color-error;
    }

    &.completed span {
      color: $color-purple;
      background-color: $color-purple-tertiary;
      border-color: $color-purple;
    }

    &.expectation span {
      color: $color-accent-orange-primary;
      background-color: $color-accent-orange-quaternary;
      border-color: $color-accent-orange-primary;
    }
  }
}

// job-performers
table.mat-table {
  &.job-performers {
    tr.mat-header-row,
    tr.mat-row {
      gap: 32px;

      @media screen and (max-width: 590px) {
        gap: 5px;
      }
    }

    // width of each header & body cell
    th.mat-header-cell,
    td.mat-cell {
      &.name {
        width: 252px;
      }

      &.status {
        width: 168px;
      }

      &.worked_out {
        width: 84px;
      }

      &.date {
        width: 150px;
      }

      &.actions {
        width: 150px;
      }

      // width job-list table
      &.title {
        width: 252px;
      }

      &.job-status {
        width: 80px;
      }

      &.hourly-pay {
        width: 100px;
      }

      &.daily-pay {
        width: 100px;
      }

      &.job-date {
        width: 150px;
      }

      &.performer {
        width: 61px;
      }
    }
  }
}

// worker-details-modal
table.mat-table {
  &.job-worker-modal {
    tr.mat-header-row,
    tr.mat-row {
      gap: 32px;
    }

    tr.mat-row {
      cursor: default;
    }

    // width of each header & body cell
    th.mat-header-cell,
    td.mat-cell {
      &.title {
        width: 345px;
      }

      &.status {
        width: 322px;
      }

      &.worked_out {
        width: 191px;
      }
    }

    tr.mat-row {
      &::before {
        display: none;
      }
    }

    td.mat-cell {
      &.title {
        letter-spacing: $spacing-1;
        font-size: 15px;
        font-weight: $weight-500;
        color: $color-text-primary;
        line-height: 33px; // indeed for ellipsis
        display: inline-block; // indeed for ellipsis
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &.status {
        text-transform: capitalize;

        span {
          padding: 4px 8px;
          letter-spacing: $spacing--001;
          line-height: 16px;
          font-size: 13px;
          font-weight: $weight-500;
          border-radius: $border-radius-5;
          border: 1px solid transparent;
        }

        &.active span {
          color: $color-successful-hover;
          background-color: $color-successful-tertiary;
          border-color: $color-successful-hover;
        }

        &.completed span {
          color: $color-purple;
          background-color: $color-purple-tertiary;
          border-color: $color-purple;
        }
      }

      &.worked-out {
        letter-spacing: $spacing--001;
        line-height: 16px;
        font-size: 13px;
        font-weight: $weight-500;
        color: $color-text-primary;
      }
    }
  }

  // Removed jobs

  .status-1,
  .status-2,
  .status-3 {
    padding: 4px 8px;
    letter-spacing: $spacing--001;
    line-height: 16px;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: $weight-500;
    border-radius: $border-radius-5;
    border: 1px solid transparent;

    @media screen and (max-width: 550px) {
      padding: 2px 4px;
      font-size: 10px;
    }
  }

  .status-1 {
    color: $color-successful-hover;
    background-color: $color-successful-tertiary;
    border-color: $color-successful-hover;
  }

  .status-2,
  .status-3 {
    color: #5c5c5c;
    background-color: #d7d7d7;
    border-color: #8a8a8a;
  }

  .date-container {
    display: flex;
    flex-direction: column;
  }
}
