mat-label {
  color: #828282;
}

.mat-placeholder-required {
  color: #828282;
}

.mat-form-field-underline {
  display: none;
}

input.w-field {
  // TODO: for inner forms only
  background-color: #EBF4F4;
  border-radius: 8px;
  height: 30px;
}

input.mat-input-element,
input,
textarea.mat-input-element {
  //&:-webkit-autofill {
  //  -webkit-box-shadow:0 0 0 50px #f5f5f5 inset;
  //  -webkit-text-fill-color: #828282;
  //}
  font-family: 'Mulish', sans-serif;

  top: 8px;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #392927;

  &.ng-dirty {
    color: #392927;
  }

  width: 100%;

  &.mat-datepicker-input {
    // top: -6px;
    // font-size: 14px;
  }

  &:disabled {
    background-color: transparent;
  }

}


input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}


// select
.mat-select-trigger {
  top: 9px;
  height: 29px;
}
