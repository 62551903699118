.row {
  display: flex;
}

.column {
  display: flex;

  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}
