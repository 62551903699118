@import '../vars';

.mat-select {
  font-family: $Mulish;
}
.mat-select-placeholder {
  letter-spacing: $spacing-1;
  line-height: 24px;
  font-size: 17px;
  color: $color-text-primary;
}
.mat-select-trigger {
  height: 24px;
}
.mat-select-arrow {
  display: none;
}
.mat-select-value {
  letter-spacing: $spacing-1;
  font-size: 15px;
  font-weight: $weight-500;
  color: $color-text-primary;
  transition: $transition-default;
}
.mat-select-value-text {
  line-height: 24px;
  font-size: 17px;
  color: $color-text-primary;
  transition: $transition-default;
}
//.mat-form-field {
//  &.mat-form-field-type-mat-select {
//    .mat-form-field-wrapper {
//      .mat-form-field-flex {
//        gap: 4px;
//
//        .mat-form-field-suffix {
//          position: absolute;
//          top: 50%;
//          right: 18px;
//          transform: translateY(-50%);
//          color: $color-text-icons;
//        }
//      }
//    }
//  }
//}

// select dropdown
.mat-select-panel-wrap {
  .mat-select-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 16px 35px 16px 16px;
    max-width: 200px;
    max-height: 400px;
    box-shadow: $box-shadow-menu-1, $box-shadow-menu-2;

    .mat-option {
      width: 100%;
      padding: 0;
      height: 16px;
      letter-spacing: $spacing--001;
      line-height: 16px;
      font-size: 13px;
      font-weight: $weight-500;
      font-family: $Mulish;
      color: $color-text-primary;
      border-radius: $border-radius-8;
      background-color: transparent;
      transition: $transition-default;

      &:hover {
        color: $color-accent-orange-primary;
      }
    }
  }
}
// TODO: add active, hover, click state colors
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: transparent;
  color: $color-accent-orange-primary;

  &:active {
    color: $color-accent-click-state;
  }
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
}
.mat-ripple:not(:empty) {
  display: none;
}

// sort select
.mat-select {
  font-family: $Mulish;

  &.select {
    .mat-select-placeholder {
      letter-spacing: $spacing-1;
      font-size: 15px;
      font-weight: $weight-500;
      color: $color-text-primary;
    }
    .mat-form-field-flex {
      gap: 4px;
    }
    .mat-form-field-suffix {
      position: relative;
      color: $color-text-icons;
    }
    .mat-select-value-text {
      letter-spacing: $spacing-1;
      font-size: 15px;
      font-weight: $weight-500;
      color: $color-text-primary;
    }
  }
}
//.mat-select-trigger {
//  height: 24px;
//}
//.mat-select-arrow {
//  display: none;
//}
//.mat-select-value {
//  letter-spacing: $spacing-1;
//  font-size: 15px;
//  font-weight: $weight-500;
//  color: $color-text-primary;
//  transition: $transition-default;
//}
//.mat-select-value-text {
//  line-height: 24px;
//  font-size: 17px;
//  color: $color-text-primary;
//  transition: $transition-default;
//}
